import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import useClassy from '@core/hooks/useClassy';
import { useReferenceStore } from '@core/store';
import { getLanguageName } from '@core/store/Reference/Language/helpers';
import type { ReferenceLanguageSliceState } from '@core/store/Reference/Language/types';

import Button from '@ui/Button';
import ButtonGroup from '@ui/ButtonGroup';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';
import Tooltip from '@ui/Tooltip';

import LanguageIcon from './LanguageIcon';
import classes from './style.module.scss';

type handleSelectionEvent = (
  e: React.KeyboardEvent | React.MouseEvent,
  lang: string,
  updateDefaultLanguages?: boolean,
) => void;

interface LanguagesProps {
  defaultLanguages: string[];
  handleSelection: handleSelectionEvent;
  isButtonGroup?: boolean;
  language: string;
  variant?: 'minimal' | 'primary';
}

interface LanguageMenuProps {
  handleSelection: handleSelectionEvent;
  language: string;
  languages: string[];
}

interface OverflowMenuLanguageDropdownProps {
  handleSelection: handleSelectionEvent;
  isButtonGroup?: boolean;
  language: string;
  overflowMenuLanguages: string[];
}

interface LanguagePickerProps {
  /**
   * All available languages, including the ones in the overflow dropdown.
   * The length of this array can exceed the value of `maxLanguages`.
   *
   * (Only used in Styleguidist, this is usually sourced from ReferenceStore)
   *
   * @example ['c', 'csharp', 'cplusplus', 'clojure', 'go', 'http', 'java', 'javascript', 'json', 'kotlin', 'node', 'objectivec', 'ocaml', 'php', 'powershell', 'python', 'r', 'ruby', 'shell', 'swift'],
   */
  availableLanguages?: string[];

  className?: string;

  /**
   * The main languages that appear in the language picker,
   * with the maximum length of this array being the value of `maxLanguages`
   *
   * (Only used in Styleguidist, this is usually sourced from ReferenceStore)
   *
   * @example ['shell', 'node', 'ruby', 'php', 'python']
   * @example ['shell', 'node', 'ruby', 'php', 'python', 'java', 'csharp']
   */
  defaultLanguages?: string[];

  /**
   * Switch between a tabbed UI variant (used in the Hub Reference), tabbed button group, or a more condensed dropdown
   * UI variant
   */
  kind?: 'dropdown' | 'tabs-button-group' | 'tabs-minimal' | 'tabs';

  /**
   * The currently selected language
   *
   * (Only used in Styleguidist, this is usually sourced from ReferenceStore)
   *
   * @example shell
   * @example node
   */
  language?: string;

  /**
   * The maximum number icons that can appear in the language picker
   * (i.e., the maximum length of the `defaultLanguages` array)
   *
   * (Only used in Styleguidist, this is usually sourced from ReferenceStore)
   */
  maxLanguages?: 5 | 7;

  /** Optional callback for handling language change events. Receives a single `language` argument. */
  onChangeLanguage?: (language: string) => void;

  /**
   * Updates the default set of (either 5 or 7) languages
   * that is surfaced in the language picker.
   *
   * This typically happens when the user selects
   * a lesser-featured language (e.g., Go)
   *
   * (Only used in Styleguidist, this is usually sourced from ReferenceStore)
   */
  updateDefaultLanguages?: (defaults: ReferenceLanguageSliceState['defaultLanguages']) => void;

  /**
   * Updates the current language and library. If library is omitted,
   * the library is set to the default library for the specified language.
   *
   * (Only used in Styleguidist, this is usually sourced from ReferenceStore)
   */
  updateLanguage?: (
    lang: ReferenceLanguageSliceState['language'],
    library?: ReferenceLanguageSliceState['languageLibrary'],
  ) => void;
}

const Languages: React.FC<LanguagesProps> = ({
  defaultLanguages,
  handleSelection,
  isButtonGroup = false,
  language,
  variant = 'primary',
}) => {
  const [key, updateKey] = useState('');
  const bem = useClassy(classes, 'LanguagePicker');

  // LanguagePicker is rendered on the server, but the active language is derived by client Cookie
  // So we need to force a re-render of the component on mount to ensure the correct language is selected
  useEffect(() => {
    updateKey(uuid());
  }, []);

  const ButtonComponent = isButtonGroup ? Button : 'button';

  return (
    <React.Fragment key={key}>
      {defaultLanguages.map(lang => {
        const languageName = getLanguageName(lang);

        return (
          <Tooltip
            key={lang}
            content={languageName}
            // only show a tooltip if we are using the minimal variant which does not have a visible label
            onShow={() => {
              return variant !== 'minimal' ? false : undefined;
            }}
          >
            <ButtonComponent
              // only provide an aria label if we are using the minimal variant which does not have text content
              aria-label={variant === 'minimal' ? languageName : undefined}
              className={bem(
                isButtonGroup ? '-button-btn-group' : '-button',
                language === lang &&
                  `${isButtonGroup ? 'rm-LanguageButtonBtnGroup_active' : 'rm-LanguageButton_active'}`,
                'rm-LanguageButton',
              )}
              onClick={e => handleSelection(e, lang)}
              outline={isButtonGroup ? true : undefined}
              size={isButtonGroup ? 'sm' : undefined}
              type={isButtonGroup ? undefined : 'button'}
            >
              <LanguageIcon
                className={bem(isButtonGroup ? '-button-btn-group-icon' : '-button-icon')}
                language={lang}
              />
              {variant !== 'minimal' && <>{languageName}</>}
            </ButtonComponent>
          </Tooltip>
        );
      })}
    </React.Fragment>
  );
};

const LanguageMenu: React.FC<LanguageMenuProps> = ({ handleSelection, language, languages: _languages }) => {
  const bem = useClassy(classes, 'LanguagePicker');

  return (
    <Menu>
      {_languages.map(lang => {
        return (
          <MenuItem
            key={`${lang}_menu_item`}
            active={language === lang}
            className={bem('-MenuItem')}
            onClick={e => handleSelection(e, lang, true)}
            onKeyPress={e => handleSelection(e, lang, true)}
          >
            <>
              <LanguageIcon className={`icon ${bem('-MenuItem-icon')}`} language={lang} />
              {getLanguageName(lang)}
            </>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

const OverflowMenuLanguageDropdown: React.FC<OverflowMenuLanguageDropdownProps> = ({
  handleSelection,
  isButtonGroup,
  language,
  overflowMenuLanguages,
}) => {
  const bem = useClassy(classes, 'LanguagePicker');

  if (!overflowMenuLanguages.length) return <></>;

  return (
    <Dropdown appendTo="parent" className={bem('-divider')} clickInToClose sticky trigger="click">
      <Button
        key="LanguagePicker-more"
        aria-label="More Languages"
        className={bem('-more', 'rm-LanguageButton-more', isButtonGroup && 'rm-LanguageButtonBtnGroup-more')}
        ghost
      >
        <Icon aria-label="More ellipsis" name="more-vertical" />
      </Button>
      <LanguageMenu handleSelection={handleSelection} language={language} languages={overflowMenuLanguages} />
    </Dropdown>
  );
};

const LanguagePicker: React.FC<LanguagePickerProps> = ({ className, kind = 'tabs', onChangeLanguage, ...props }) => {
  const [availableLanguages, defaultLanguages, language, maxLanguages, updateDefaultLanguages, updateLanguage] =
    useReferenceStore(store => [
      props.availableLanguages ?? store.language.availableLanguages,
      props.defaultLanguages ?? store.language.defaultLanguages,
      props.language ?? (store.language.language as NonNullable<typeof store.language.language>),
      props.maxLanguages ?? store.language.maxLanguages,
      props.updateDefaultLanguages ?? store.language.updateDefaultLanguages,
      props.updateLanguage ?? store.language.updateLanguage,
    ]);

  const bem = useClassy(classes, 'LanguagePicker');

  const handleSelection = useCallback(
    (e, lang, shouldUpdateDefaultLanguages = false) => {
      e.preventDefault();

      if (shouldUpdateDefaultLanguages) {
        // If the language we're switching to is already in the set of default languages then we don't
        // need to swap one out.
        if (!defaultLanguages.includes(lang)) {
          const newDefaults = [...defaultLanguages];
          if (defaultLanguages.length >= maxLanguages) {
            // If we’re rendering the maximum amount of defaults already we need to remove the last
            // entry.
            newDefaults.pop();
          }

          newDefaults.push(lang);
          updateDefaultLanguages(newDefaults);
        }
      }

      updateLanguage(lang);
      onChangeLanguage?.(lang);
    },
    [defaultLanguages, maxLanguages, onChangeLanguage, updateDefaultLanguages, updateLanguage],
  );

  // Overflow menu shouldn't include lanuages already shown as tabs
  const overflowMenuLanguages = useMemo(
    () => availableLanguages.filter(lang => defaultLanguages.indexOf(lang) < 0),
    [defaultLanguages, availableLanguages],
  );

  switch (kind) {
    case 'tabs-button-group':
      return (
        <div className={bem('&', '-Btn-group', className, 'rm-LanguagePicker')}>
          <ButtonGroup>
            <Languages
              defaultLanguages={defaultLanguages}
              handleSelection={handleSelection}
              isButtonGroup
              language={language}
            />
            <OverflowMenuLanguageDropdown
              key="overflow-menu-lang-dropdown"
              handleSelection={handleSelection}
              isButtonGroup
              language={language}
              overflowMenuLanguages={overflowMenuLanguages}
            />
          </ButtonGroup>
        </div>
      );
    case 'dropdown':
      return (
        <Dropdown className={bem('-Dropdown', className, 'rm-LanguagePicker')} clickInToClose justify="start" sticky>
          <Button className={bem('-Dropdown-button')} dropdown ghost kind="secondary" size="sm">
            <LanguageIcon className={bem('-Dropdown-icon', 'icon')} language={language} />
            {getLanguageName(language)}
          </Button>
          <LanguageMenu handleSelection={handleSelection} language={language} languages={availableLanguages} />
        </Dropdown>
      );
    case 'tabs-minimal':
      return (
        <div className={bem('&', '-Minimal', className, 'rm-LanguagePicker')}>
          <div className={bem('-languages')}>
            <Languages
              defaultLanguages={defaultLanguages}
              handleSelection={handleSelection}
              language={language}
              variant="minimal"
            />
            <OverflowMenuLanguageDropdown
              handleSelection={handleSelection}
              language={language}
              overflowMenuLanguages={overflowMenuLanguages}
            />
          </div>
        </div>
      );
    case 'tabs':
    default:
      return (
        <div className={bem('&', className, 'rm-LanguagePicker')}>
          <div className={bem('-languages')}>
            <Languages defaultLanguages={defaultLanguages} handleSelection={handleSelection} language={language} />
            <OverflowMenuLanguageDropdown
              handleSelection={handleSelection}
              language={language}
              overflowMenuLanguages={overflowMenuLanguages}
            />
          </div>
        </div>
      );
  }
};

export { default as LanguageIcon } from './LanguageIcon';
export default React.memo(LanguagePicker);
